import React from "react"
import { Box, Divider, Text, VStack } from "@chakra-ui/react"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { VomuxTextStandard } from "../shared/vomux_text"
import { SessionCodeRunData } from "../../services/types"
import moment from "moment"

interface CodeOutputProps {
  codeRuns: SessionCodeRunData[]
}

export const CodeOutput: React.FC<CodeOutputProps> = (
  props: CodeOutputProps
) => {
  return (
    <VStack
      padding="1rem"
      borderRadius={"10px"}
      backgroundColor={"white"}
      width={"30vw"}
      height={"77vh"}
    >
      <Box width="100%" mb="1rem">
        <VomuxTextStandard fontSize={"18px"} color={COLORS_PRIMARY.BLACK}>
          Code Output
        </VomuxTextStandard>
        <Divider borderWidth="1" w="90%" />
      </Box>
      <Box w={"28vw"} h={"64vh"} overflowY="scroll">
        {props.codeRuns.map(codeRun => {
          return (
            <Box>
              <Text fontSize="md" color="gray.500">
                {`Ran ${moment(codeRun.created_at).fromNow()}`}
              </Text>
              <Text mt={2}>
                {codeRun.stdout}
              </Text>
            </Box>
          )
        })}
      </Box>
    </VStack>
  )
}

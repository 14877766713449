import React, { useContext, useEffect, useState } from "react"
import {
  Box,
  Button,
  HStack,
  Select,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { navigate } from "gatsby"
import { CodeOutput } from "./output"
import { Participants } from "./participants"
import { ApiContext, CompanyContext } from "../Root"
import { WebsocketEditor } from "../shared/websocket_editor"
import { InterviewInviteModal } from "./interview_invite_modal"
import {
  AnalyticsEvents,
  InterviewData,
  SessionTypes,
} from "../../services/types"
import { getUuidFromPath } from "../../utils/path"
import { LANGUAGES } from "../../utils/languages"
import { sendAmplitudeEvent } from "../../services/amplitude"

export const InterviewSession: React.FC = props => {
  const api = useContext(ApiContext)
  const company = useContext(CompanyContext)
  const [editorLanguage, setEditorLanguage] = useState("javascript")
  const [code, setCode] = useState("")
  const [codeRuns, setCodeRuns] = useState([])
  const [sessionInvites, setSessionInvites] = useState([])
  const [accountInfos, setAccountInfos] = useState([])
  const [interview, setInterview] = useState<InterviewData>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function inviteEmail(email: string) {
    if (!company.data?.company_id) {
      return
    }
    const session_id = getUuidFromPath()
    if (!session_id) {
      return
    }
    sendAmplitudeEvent(AnalyticsEvents.interview_session_invite_sent, {
      session_id,
      session_type: SessionTypes.interview,
      email: email,
    })
    await api.client.createInterviewSessionInvite(company.data.company_id, {
      session_id,
      session_type: SessionTypes.interview,
      email: email,
    })
    await refreshSessionInvites()
  }

  async function loadInterview() {
    const interviewId = getUuidFromPath()
    if (!interviewId) {
      return
    }
    const interviewRes = await api.client.getInterviewById(interviewId)
    if (!interviewRes) {
      alert("There was an issue loading your interview.")
      return
    }
    setInterview(interviewRes)
    setEditorLanguage(interviewRes.language)
  }

  async function refreshCodeRuns() {
    const interviewId = getUuidFromPath()
    if (!interviewId) {
      return
    }
    const sessionCodeRunsRes = await api.client.getSessionCodeRuns(interviewId)
    if (!sessionCodeRunsRes) {
      return
    }
    setCodeRuns(sessionCodeRunsRes.reverse())
  }

  async function refreshSessionInvites() {
    const interviewId = getUuidFromPath()
    if (!interviewId) {
      return
    }
    const sessionInvitesRes = !company.data?.company_id
      ? await api.client.getInterviewSessionInvitesBySessionIdForCandidate(
          interviewId
        )
      : await api.client.getInterviewSessionInvitesBySessionId(
          company.data.company_id,
          interviewId
        )
    if (!sessionInvitesRes || sessionInvitesRes.length < 1) {
      return
    }
    setSessionInvites(sessionInvitesRes)
    const accountIds = [
      ...new Set(
        sessionInvitesRes
          .filter(invite => !!invite.account_id)
          .map(sessionInvite => sessionInvite.account_id)
      ),
    ]
    console.log("account ids", accountIds)
    if (!accountIds || accountIds.length < 1) {
      return
    }
    const accounts = await api.client.getAccounts(accountIds)
    if (!accounts || accounts.length < 1) {
      return
    }
    setAccountInfos(accounts)
  }

  async function loadInterviewsAndCodeRuns() {
    await loadInterview()
    await refreshCodeRuns()
  }
  useEffect(() => {
    if (!getUuidFromPath()) {
      navigate("/home")
    }
  }, [])
  useEffect(() => {
    loadInterviewsAndCodeRuns()
  }, [api.client.token])
  useEffect(() => {
    refreshSessionInvites()
  }, [company.data?.company_id])

  async function runCode() {
    const session_id = getUuidFromPath()
    sendAmplitudeEvent(AnalyticsEvents.interview_session_code_ran, {
      language: editorLanguage,
      session_id,
    })
    await api.client.runCode({
      code,
      language: editorLanguage,
      session_id,
    })
    await refreshCodeRuns()
  }

  async function saveCode() {
    const session_id = getUuidFromPath()
    sendAmplitudeEvent(AnalyticsEvents.interview_session_code_ran, {
      language: editorLanguage,
      session_id,
    })
    const updatedInterview = await api.client.updateInterview(session_id, {
      code,
    })
    if (!updatedInterview.success) {
      alert(`Issue saving code: ${updatedInterview.message}`)
      return
    }
  }

  return (
    <HStack
      backgroundColor={COLORS_PRIMARY.BACKGROUND_BLUE}
      h="100vh"
      w="100vw"
    >
      <VStack height={"92vh"} width={"50vw"}>
        <Box w="100%">
          <Button
            _focus={{ outline: "none" }}
            size="lg"
            backgroundColor={COLORS_PRIMARY.WHITE}
            marginLeft={"2rem"}
            onClick={() => {
              sendAmplitudeEvent(
                AnalyticsEvents.interview_session_back_clicked,
                {}
              )
              navigate("/home")
            }}
          >
            {"<| Back"}
          </Button>
        </Box>

        <Box
          borderRadius={"10px"}
          padding=".5rem"
          marginTop={"1rem"}
          height={"77vh"}
          width={"45vw"}
          backgroundColor={COLORS_PRIMARY.WHITE}
        >
          <Box
            marginBottom={"1rem"}
            justifyContent={"space-between"}
            paddingLeft={"1rem"}
            display={"flex"}
            flexDirection={"row"}
          >
            {interview?.interview_id ? (
              <Select
                defaultValue={interview.language || LANGUAGES.javascript}
                onChange={async e => {
                  sendAmplitudeEvent(
                    AnalyticsEvents.interview_session_language_changed,
                    {
                      language: e.target.value,
                    }
                  )
                  setEditorLanguage(e.target.value)
                  await api.client.updateInterview(interview.interview_id, {
                    language: e.target.value,
                  })
                  // TODO broadcast a language change event
                }}
                width="10rem"
              >
                {Object.keys(LANGUAGES).map(key => {
                  return <option value={key}>{LANGUAGES[key]}</option>
                })}
              </Select>
            ) : undefined}
            <Spacer />
            <Button
              backgroundColor={COLORS_PRIMARY.DARK_BLUE}
              color={COLORS_PRIMARY.WHITE}
              justifySelf={"right"}
              mr="1rem"
              onClick={saveCode}
            >
              Save
            </Button>
            <Button
              backgroundColor={COLORS_PRIMARY.LIGHT_BLUE}
              color={COLORS_PRIMARY.WHITE}
              justifySelf={"right"}
              onClick={runCode}
            >
              Run Code
            </Button>
          </Box>
          {interview?.interview_id ? (
            <WebsocketEditor
              getSessionId={getUuidFromPath}
              language={editorLanguage}
              height="55vh"
              setCode={setCode}
              defaultCode={interview.code}
            />
          ) : undefined}
        </Box>
      </VStack>
      <HStack width="48vw">
        <CodeOutput codeRuns={codeRuns} />
        <Participants
          onInvite={onOpen}
          invites={sessionInvites}
          accounts={accountInfos}
        />
      </HStack>
      <InterviewInviteModal
        onInvite={inviteEmail}
        isOpen={isOpen}
        onClose={onClose}
      />
    </HStack>
  )
}

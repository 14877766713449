import { Box } from "@chakra-ui/react"
import React from "react"
import Helmet from "react-helmet"

import { InterviewSession } from "../components/interview/interview"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import { AnalyticsEvents } from "../services/types"

const InterviewPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.interview_session_landed)
  return (
    <Box>
      <Seo title="Interview" />
      <InterviewSession />
    </Box>
  )
}

export default InterviewPage

import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { COLORS_PRIMARY } from "../shared/vomux_colors"

export const InterviewInviteModal = props => {
  const [email, setEmail] = useState("")
  async function onClick() {
    if (!email) {
      return
    }
    await props.onInvite(email)
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent h={48}>
        <ModalCloseButton />
        <Center mt={8}>
          <Text>Invite a candidate</Text>
        </Center>
        <HStack mt={4}>
          <Text ml="1rem">Email:</Text>

          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            ml={1}
            w="80%"
            autoFocus
          />
        </HStack>

        <Center>
          <Button
            onClick={onClick}
            color="white"
            backgroundColor={COLORS_PRIMARY.DARK_BLUE}
            mt={4}
            w="48%"
          >
            Send invite
          </Button>
        </Center>
      </ModalContent>
    </Modal>
  )
}

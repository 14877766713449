import React from "react"
import { Box, Divider, HStack, Icon, Text, VStack } from "@chakra-ui/react"
import { VomuxButton } from "../shared/vomux_button"
import { COLORS_PRIMARY } from "../shared/vomux_colors"

import {
  MdCheckCircle,
  MdHowToReg,
  MdPersonAddAlt1,
  MdRemoveCircle,
} from "react-icons/md"
import { VomuxTextStandard } from "../shared/vomux_text"
import { AccountInfo, SessionInviteJoinData } from "../../services/types"

interface ParticipantsProps {
  onInvite: Function
  invites: SessionInviteJoinData[]
  accounts: AccountInfo[]
}

const renderAccount = (accountInfo: AccountInfo) => {
  return (
    <HStack>
      <Icon
        color={COLORS_PRIMARY.DARK_BLUE}
        w={6}
        h={6}
        mb="1.2rem"
        mr="0.4rem"
        as={MdHowToReg}
      />
      <VStack>
        <Text overflow="clip">
          {`${accountInfo.first_name}`}
          <Box fontSize="sm" color="gray.400">
            Interviewer
          </Box>
        </Text>
      </VStack>
    </HStack>
  )
}

const renderParticipant = (invite: SessionInviteJoinData) => {
  return (
    <HStack
      onClick={async () => {
        if (typeof window !== "undefined") {
          await window.navigator.clipboard.writeText(
            `${process.env.GATSBY_WWW_HOST}/signup/${invite.session_invite_id}`
          )
        }
      }}
    >
      {!invite.accepted_account_id ? (
        <Icon
          color="gray.400"
          mb="1.2rem"
          mr="0.4rem"
          w={6}
          h={6}
          as={MdRemoveCircle}
        />
      ) : (
        <Icon
          color={COLORS_PRIMARY.DARK_BLUE}
          w={6}
          h={6}
          mb="1.2rem"
          mr="0.4rem"
          as={MdCheckCircle}
        />
      )}
      <VStack>
        <Text overflow="clip">
          {invite.accepted_account_id ? `${invite.first_name}` : `Pending`}
          <Box fontSize="sm" color="gray.400">
            Interviewee
          </Box>
        </Text>
      </VStack>
    </HStack>
  )
}

export const Participants: React.FC<ParticipantsProps> = (
  props: ParticipantsProps
) => {
  return (
    <Box
      padding="1rem"
      borderRadius={"10px"}
      backgroundColor={"white"}
      width={"16vw"}
      height={"77vh"}
    >
      <VomuxButton
        width="100%"
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        backgroundColor={COLORS_PRIMARY.LIGHT_BLUE}
        onClick={props.onInvite}
      >
        <Icon marginRight={"0.5rem"} as={MdPersonAddAlt1} />
        Invite
      </VomuxButton>
      <Box>
        <Divider marginTop={"1rem"} />
        <Box
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"row"}
        >
          <VomuxTextStandard color={COLORS_PRIMARY.BLACK} fontSize={"1rem"}>
            Participants
          </VomuxTextStandard>
          {/* <Image src={UpArrowIcon} /> */}
        </Box>
        <VStack mt="1rem" h="45vh" overflowY="scroll">
          {props.accounts.map(renderAccount)}
          {props.invites.map(renderParticipant)}
        </VStack>
      </Box>
    </Box>
  )
}

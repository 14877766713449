import React from "react"
import * as Y from "yjs"
import { WebsocketProvider } from "y-websocket"
import Editor from "@monaco-editor/react"

interface WebsocketEditorProps {
  getSessionId: Function
  language: string
  height: string
  setCode?: Function
  defaultCode?: string
}

export const WebsocketEditor = (props: WebsocketEditorProps) => {
  async function setupEditor(monacoEditor) {
    if (!monacoEditor) {
      console.log("no monaco editor present")
      return
    }
    if (!props.getSessionId()) {
      console.log("No Session ID present for editor.")
      return
    }

    const ydoc = new Y.Doc()
    const wsProvider = new WebsocketProvider(
      process.env.GATSBY_WEBSOCKET_URL,
      `${props.getSessionId()}-${props.language}`,
      ydoc
    )
    const { MonacoBinding } = await import("y-monaco")
    new MonacoBinding(
      ydoc.getText("monaco"),
      monacoEditor.getModel(),
      new Set([monacoEditor]),
      wsProvider.awareness
    )
    console.log("websocket bound")
  }

  function handleChange(value, change) {
    // TODO record changes per account
    if (props.setCode) {
      props.setCode(value)
    }
  }

  function getLanguage() {
    if (props.language === "golang") {
      return "go"
    }
    return props.language
  }

  return (
    <Editor
      defaultValue={props.defaultCode}
      language={getLanguage()}
      onMount={setupEditor}
      onChange={handleChange}
      height={props.height}
    />
  )
}
